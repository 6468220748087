<template>
  <el-row id="app">
    <router-view />
  </el-row>
</template>
<script>
export default {
  name: 'App'
}
</script>
